import { ReviewChecklistDetails } from '@/types';

export const APP_BASE_PATH = '';

export enum REVIEW_STATUS {
  NOT_SUBMITTED = 'not-submitted',
  IN_REVIEW = 'in-review',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PUSHED_BACK = 'pushed-back',
}
export const DEPLOYMENT_STATUSES = Object.freeze({
  production: 'production',
  private: 'non-production',
  deprecated: 'deprecated',
});
export const VERSION_STATUSES = Object.freeze({
  deprecated: 'deprecated',
  private: 'private',
  public: 'public',
});
export const defaultReview: ReviewChecklistDetails = {
  schemaVersion: '2.0.0',
  reviewStatus: 'in-review',
  appName: '',
  appID: '',
  zendeskID: '',
  slackThread: '',
  checklist: [
    {
      value: 'unset',
      key: 'prohibited_restricted_country',
      label: 'App is not from a restricted country',
      title: 'Currently Iran, North Korea, Syria, Cuba and the Crimea region',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#1-1-apps-from-a-restricted-country',
      note: '',
    },
    {
      value: 'unset',
      key: 'prohibited_competitor',
      label: 'App is not from a competitor',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#1-2-apps-from-a-competitor',
      note: '',
    },
    {
      value: 'unset',
      key: 'prohibited_app_agreements',
      label: 'App meets Zapier and third-party agreements',
      title:
        'Check for any obvious *potential* discretions. Any signs our Platform Agreement or TOS are being violated? If so, try to get them resolved. Does the app/integration use third-party APIs e.g. WhatsApp? If so, ask the submitter to confirm they have the appropriate permissions.',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#1-3-apps-that-do-not-meet-zapier-or-third-party-agreements',
      note: '',
    },
    {
      value: 'unset',
      key: 'prohibited_int_financial',
      label: 'Integration does not make financial transactions',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#1-4-integrations-that-make-financial-transactions',
      note: '',
    },
    {
      value: 'unset',
      key: 'prohibited_misleading',
      label:
        'App/Integration does not have misleading or malicious functionality',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#1-5-apps-that-have-misleading-or-malicious-functionality',
      note: '',
    },
    {
      value: 'unset',
      key: 'prohibited_objectionable',
      label: 'App/Integration does not have objectionable content',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#1-6-apps-that-have-objectionable-content',
      note: '',
    },
    {
      value: 'unset',
      key: 'restricted_int_replacement',
      label: 'Integration is not a replacement',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#2-1-replacement-integrations',
      note: '',
    },
    {
      value: 'unset',
      key: 'restricted_int_multiple',
      label: 'App not already published under another name',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#2-2-multiple-integrations',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_app_public_launch',
      label: 'Application has been publicly launched',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#5-1-app-has-been-publicly-launched',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_app_test_acc_valid',
      label: 'Application has valid test account (details saved in 1Password)',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#7-1-application-has-a-valid-test-account',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_app_api_docs',
      label: 'Application API is documented',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#5-2-app-apis-are-documented',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_name_valid',
      label: 'Integration name is unique and matches core branding',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#6-1-integration-name-is-unique-and-matches-your-applications-core-branding',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_desc_valid',
      label: 'Integration description follows convention',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#6-2-integration-description-follows-convention',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_url_valid',
      label: 'Integration homepage URL correctly set',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#6-3-integration-homepage-url-correctly-set',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_admin_team_member',
      label: "Integration has qualifying team member (admin with app's domain)",
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#7-2-integration-has-a-qualifying-team-member',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_trademark_permission',
      label: 'Developer owns or has permission to use all trademarks used',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#3-2-trademarks',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_prod_apis',
      label: 'Integration uses production APIs',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#5-4-integration-uses-production-apis',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_https',
      label: 'Integration uses HTTPS',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#4-2-integration-uses-https',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_api_permission',
      label: 'Developer owns or has permission to use all APIs',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#3-1-apis',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_prod_ready',
      label: 'Integration is production-ready',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#5-3-integration-is-production-ready',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_data_handling',
      label: 'Integration handles data appropriately',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#4-1-integration-handles-data-appropriately',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_secure_creds',
      label: 'Integration stores authentication credentials securely',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#4-3-integration-stores-credentials-securely',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_auth_creds',
      label: 'Integration requests authentication credentials appropriately',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#5-5-integration-requests-authentication-credentials-appropriately ',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_conn_label',
      label: 'Integration has valid connection label',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#5-6-integration-has-a-valid-connection-label',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_eng_lang',
      label: 'Integration uses English language',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#5-7-integration-uses-english-language',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_naming_conv',
      label: 'Integration follows naming conventions',
      title: '',
      link: 'https://platform.zapier.com/publish/integration-publishing-requirements#5-8-integration-follows-naming-conventions',
      note: '',
    },
    {
      value: 'unset',
      key: 'core_int_service_consistency_key',
      label: 'Selected Service & Consistency Key are appropriate',
      title:
        'Be on the lookout for “requested” services, which have been created to track customer interest.',
      link: '#',
      note: '',
    },
  ],
  firstReviewed: Date.now(),
  lastReviewed: Date.now(),
  lastReviewedBy: '',
  reviewedBy: [],
};

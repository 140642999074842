import { getJson } from '@zapier/toolbox-browser-fetch';

import { Integration } from '@/types';
import { getUrls } from '@/utils';

export function formatResponseBody(body: any): Integration {
  return {
    appCategory: body?.app_category ?? '',
    date: body?.date ?? '',
    description: body?.description ?? '',
    id: body?.id ?? NaN,
    image: body?.image ?? '',
    intention: body?.intention ?? '',
    inviteUrl: body?.invite_url ?? '',
    key: body?.key ?? '',
    role: body?.role ?? '',
    serviceId: body?.service_id ?? NaN,
    slug: body?.slug ?? '',
    title: body?.title ?? '',
  };
}

export async function fetchIntegration({
  integrationId,
}: {
  integrationId: number;
}): Promise<Integration> {
  const url = new URL(
    `/api/platform/cli/apps/${encodeURIComponent(integrationId)}`,
    getUrls().apiBase,
  );

  const responseBody = await getJson(url.href);
  return formatResponseBody(responseBody);
}

/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { ReviewExtended } from '@/types';

import Table from './Table';

const Styles = {
  root: css`
    font-size: 15;
  `,
};

type Props = {
  reviews: Array<ReviewExtended>;
};

export default function ReviewList({ reviews }: Props) {
  // Filter out null integrations (occurs when one has been deleted after a review was created) rather than crashing.
  const reviewsNormalized = reviews
    .filter((review) => !!review.integration)
    .map((review) => {
      const { integration } = review;
      const { id } = integration;

      return {
        ...review,
        integration: {
          ...review.integration,
          id: typeof id === 'number' ? id : parseInt(id),
        },
      };
    });

  return reviewsNormalized.length ? (
    <div css={Styles.root}>
      <Table reviews={reviewsNormalized} />
    </div>
  ) : null;
}

'use client';

import { fetchIntegrations } from './utils';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

type Args = Parameters<typeof fetchIntegrations>[0];
type Data = Awaited<ReturnType<typeof fetchIntegrations>>;

const QUERY_KEY_PREFIX = 'integrations-query';
const getQueryKey = (args: Args) => [
  `${QUERY_KEY_PREFIX}-${args.integrationIds}`,
];

export default function useIntegrationQuery(
  args: Args,
  options?: Partial<UseQueryOptions<Data, Error>>,
) {
  const queryKey = getQueryKey(args);

  const query = useQuery({
    queryKey,
    queryFn: async () => fetchIntegrations(args),
    ...options,
  });

  return { ...query, queryKey };
}

export { getQueryKey, QUERY_KEY_PREFIX };

export const TABS = {
  inReview: {
    href: '#in-review',
    text: 'In Review',
  },
  approved: {
    href: '#approved',
    text: 'Approved',
  },
  rejected: {
    href: '#rejected',
    text: 'Rejected',
  },
  resubmitted: {
    href: '#re-submitted',
    text: 'Resubmitted',
  },
};

/** @jsxImportSource @emotion/react */

import { format, formatDistanceToNow } from 'date-fns';

import { css } from '@emotion/react';
import { Colors, Link } from '@zapier/design-system';
import { Border, Colors as ThemeColors, Spacing } from '@zapier/theme';

import { AppLogo } from '@/components';
import { APP_BASE_PATH } from '@/constants';
import { ReviewExtended } from '@/types';

const Styles = {
  row: ({ isDark }: { isDark?: boolean }) => [
    css`
      vertical-align: middle;
      :not(:last-child) {
        border-bottom: 1px solid ${Border.color.default};
      }
      :hover {
        background-color: ${Colors.gray20};
      },
    `,
    isDark &&
      css`
        background-color: ${Colors.gray10};
      `,
  ],
  cell: css`
    line-height: 1.5;
    padding: ${Spacing.medium}px;
    text-align: center;
    color: ${ThemeColors.steel};
    :first-of-type {
      max-width: 250px;
      text-align: left;
    }
    white-space: nowrap;
  `,
  link: css`
    text-decoration: none !important;
  `,
  logo: css`
    display: inline-flex;
    margin-right: ${Spacing.medium}px;
    vertical-align: middle;
  `,
  nameCell: css`
    display: flex;
    align-items: center;
  `,
  nameText: css`
    white-space: initial;
  `,
};

type Props = {
  isDark?: boolean;
  review: ReviewExtended;
};

export default function TableRow({ review, isDark }: Props) {
  const { id: reviewId, integration } = review;
  const { id: integrationId } = integration;
  const url = `${APP_BASE_PATH}/review/${reviewId}?integrationId=${integrationId}`;

  return (
    <tr css={Styles.row({ isDark })}>
      <td css={Styles.cell}>
        <Link css={Styles.link} href={url}>
          <div css={Styles.nameCell}>
            <span css={Styles.logo}>
              <AppLogo url={review.integration.image || undefined} />
            </span>
            <span aria-label="name" css={Styles.nameText}>
              {review.integration.title}
            </span>
          </div>
        </Link>
      </td>
      <td css={Styles.cell}>
        <Link css={Styles.link} href={url}>
          {review.integration.id}
        </Link>
      </td>
      <td css={Styles.cell}>
        <Link css={Styles.link} href={url}>
          {format(review.createdAt, 'MMM dd, yyyy')}
        </Link>
      </td>
      <td css={Styles.cell}>
        <Link css={Styles.link} href={url}>
          {formatDistanceToNow(review.updatedAt)} ago
        </Link>
      </td>
    </tr>
  );
}

import isProduction from './isProduction';

export default function getUrls() {
  return {
    apiBase: isProduction()
      ? 'https://zapier.com'
      : global.window?.location?.origin,
    apiBaseHooks: 'https://hooks.zapier.com',
    apiBaseStorage: 'https://store.zapier.com',
    developerPlatform: isProduction()
      ? 'https://zapier.com/developer-platform'
      : 'https://zapier-staging.com/developer-platform',
    logIn: isProduction() ? 'https://zapier.com/app/login' : '/app/login',
    logOut: isProduction() ? 'https://zapier.com/logout' : '/logout',
    vbBase: isProduction()
      ? 'https://developer.zapier.com'
      : 'https://developer.zapier-staging.com',
  };
}

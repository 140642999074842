import { getJson } from '@zapier/toolbox-browser-fetch';

import { Integration } from '@/types';
import { getUrls } from '@/utils';

import { formatResponseBody } from '../useIntegrationQuery';

export async function fetchIntegrations({
  integrationIds = [],
}: {
  integrationIds?: number[];
}): Promise<Integration[]> {
  const integrationIdsString = JSON.stringify(integrationIds);
  const ids: number[] = JSON.parse(integrationIdsString);

  const urls = ids.map(
    (id) =>
      new URL(
        `/api/platform/cli/apps/${encodeURIComponent(id)}`,
        getUrls().apiBase,
      ),
  );
  const responses = await Promise.allSettled(
    urls.map((url) => getJson(url.href)),
  );
  const responsesFulfilled = responses.filter(
    (response) => response.status === 'fulfilled' && response.value,
  ) as PromiseFulfilledResult<any>[];
  const integrations = responsesFulfilled.map((response) =>
    formatResponseBody(response.value),
  );

  return integrations;
}

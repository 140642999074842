/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Icon } from '@zapier/design-system';
import { Spacing } from '@zapier/theme';

const Styles = {
  root: css`
    line-height: 1.5;
    padding: ${Spacing.small}px;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;
    font-size: 13px;
    white-space: nowrap;
    :first-of-type {
      text-align: left;
    }
    :hover {
      cursor: pointer;
    }
  `,
  headerCellElement: css`
    vertical-align: middle;
    white-space: nowrap;
    :not(:first-of-type) {
      margin-left: 5px;
    }
  `,
};

type Props = {
  text: string;
  onClickSort: () => void;
};

export default function TableHeaderCell({ onClickSort, text }: Props) {
  return (
    <th css={Styles.root} onClick={onClickSort}>
      <span css={Styles.headerCellElement}>{text}</span>
      <span css={Styles.headerCellElement}>
        <Icon name="arrowDropdown" size={10} />
      </span>
    </th>
  );
}

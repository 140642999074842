/** @jsxImportSource @emotion/react */
'use client';

import { css } from '@emotion/react';

import { AuthedPage } from '@/components';

import Dashboard from './Dashboard';

const Styles = {
  root: css`
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 32px 0;
  `,
};

export default function Page() {
  return (
    <AuthedPage>
      <div css={Styles.root}>
        <Dashboard />
      </div>
    </AuthedPage>
  );
}

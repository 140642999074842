'use client';

import { PropsWithChildren } from 'react';

import { CTACentered, Spinner } from '@zapier/design-system';

import { useUserSessionQuery } from '@/hooks';

export default function AuthedPage({ children }: PropsWithChildren) {
  const { data: userSession, isLoading } = useUserSessionQuery();
  const isAuthenticated = userSession?.isAuthenticated ?? false;
  const isStaff = userSession?.isStaff ?? false;

  return isLoading ? (
    <Spinner />
  ) : !isAuthenticated ? (
    <CTACentered
      backgroundColor="night"
      ctaBtnColor="brand"
      ctaText="Log in"
      heading="🔐 Not authenticated"
      subHeading="Please log in to access"
    />
  ) : !isStaff ? (
    <CTACentered
      backgroundColor="night"
      ctaBtnColor="brand"
      heading="⛔️ You shall not pass"
      subHeading="Staff only"
    />
  ) : (
    children
  );
}

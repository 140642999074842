import { getJson } from '@zapier/toolbox-browser-fetch';

import { Review } from '@/types';
import { getUrls } from '@/utils';

import { formatResponseBody as formatResponseBodyReview } from '../useReviewQuery';

function formatResponseBody(body: any): Review[] {
  const reviews = body ?? [];

  return reviews.map(formatResponseBodyReview);
}

export async function fetchReviews({
  isResubmission = undefined,
  status,
}: {
  isResubmission?: boolean;
  status: Review['status'];
}): Promise<Review[]> {
  const url = new URL('/api/platform/cli/reviews', getUrls().apiBase);
  const searchParams = new URLSearchParams({ status });
  if (isResubmission === true) {
    searchParams.append('is_resubmission', String(isResubmission));
  }
  url.search = searchParams.toString();

  const responseBody = await getJson(url.href);
  return formatResponseBody(responseBody);
}

'use client';

import { fetchUserSession } from './utils';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

type Data = Awaited<ReturnType<typeof fetchUserSession>>;

const QUERY_KEY_PREFIX = 'user-session-query';
const getQueryKey = () => [QUERY_KEY_PREFIX];

export default function useUserSessionQuery(
  options?: Partial<UseQueryOptions<Data, Error>>,
) {
  const queryKey = getQueryKey();

  const query = useQuery({
    queryKey,
    queryFn: async () => fetchUserSession(),
    ...options,
  });

  return { ...query, queryKey };
}

export { getQueryKey, QUERY_KEY_PREFIX };

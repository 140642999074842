import { getJson } from '@zapier/toolbox-browser-fetch';

import { REVIEW_STATUS } from '@/constants';
import { Review } from '@/types';
import { getUrls } from '@/utils';

export function formatResponseBody(body: any): Review {
  return {
    createdAt: body?.created_at ?? '',
    id: body?.id ?? NaN,
    integrationId: body?.app ?? NaN,
    isResubmission: body?.is_resubmission,
    status: body?.status ?? REVIEW_STATUS.NOT_SUBMITTED,
    ticketId: body?.ticket_id ?? NaN,
    updatedAt: body?.updated_at ?? '',
    version: body?.app_version_str ?? '',
  };
}

export async function fetchReview({
  integrationId,
}: {
  integrationId: number;
}): Promise<Review> {
  const url = new URL(
    `/api/platform/cli/apps/${encodeURIComponent(integrationId)}/review`,
    getUrls().apiBase,
  );

  const responseBody = await getJson(url.href);
  return formatResponseBody(responseBody);
}

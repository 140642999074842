import {
  ServiceIcon,
  ServiceIconShell,
  ServiceIconSize,
} from '@zapier/design-system';

type Props = {
  size?: ServiceIconSize;
  url?: string;
};

const defaultProps = {
  size: 40 as const,
  url: '',
};

/**
 * Renders App Logo
 * When the imageUrl isn't provided, it will render the default placeholder
 */
export default function AppLogo({
  size = defaultProps.size,
  url = defaultProps.url,
}: Props) {
  return url ? (
    <ServiceIcon serviceName="App Logo" size={size} src={url} />
  ) : (
    <ServiceIconShell size={size} />
  );
}

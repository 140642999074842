'use client';

import { fetchReviews } from './utils';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';

type Args = Parameters<typeof fetchReviews>[0];
type Data = Awaited<ReturnType<typeof fetchReviews>>;

const QUERY_KEY_PREFIX = 'reviews-query';
const getQueryKey = (args: Args) => [
  `${QUERY_KEY_PREFIX}-${args.isResubmission}-${args.status}`,
];

export default function useReviewsQuery(
  args: Args,
  options?: Partial<UseQueryOptions<Data, Error>>,
) {
  const queryKey = getQueryKey(args);

  const query = useQuery({
    queryKey,
    queryFn: async () => fetchReviews(args),
    ...options,
  });

  return { ...query, queryKey };
}

export { getQueryKey, QUERY_KEY_PREFIX };

/** @jsxImportSource @emotion/react */

import { useState } from 'react';
import { identity, reverse, sortBy } from 'lodash';

import { css } from '@emotion/react';
import { Colors } from '@zapier/design-system';
import { Border, Forms, Spacing } from '@zapier/theme';

import { ReviewExtended } from '@/types';

import TableHeaderCell from './TableHeaderCell';
import TableRow from './TableRow';

const Styles = {
  root: css`
    background: ${Colors.white};
    border: ${Forms.field.border};
    border-collapse: collapse;
    border-radius: ${Border.radius.default};
    margin: ${Spacing.none}px;
    width: 100%;
    & tbody tr {
      cursor: pointer;
    }
  `,
  headerRow: css`
    border-bottom: ${Forms.field.border};
    text-transform: uppercase;
    background-color: ${Colors.gray10};
  `,
};

type OrderedBy = 'assignee' | 'id' | 'createdAt' | 'name' | 'updatedAt';

type Props = {
  reviews: Array<ReviewExtended>;
};

export default function Table({ reviews }: Props) {
  const [isDescendant, setIsDescendant] = useState(true);
  const [orderedBy, setOrderedBy] = useState<OrderedBy>('updatedAt');
  const reviewsSorted = (isDescendant ? reverse : identity)(
    sortBy(
      reviews,
      ['id', 'name'].includes(orderedBy)
        ? `integration.${orderedBy === 'name' ? 'title' : orderedBy}`
        : orderedBy,
    ),
  );

  const onClickSort = (nextOrderedBy: OrderedBy) => {
    if (orderedBy !== nextOrderedBy) {
      setIsDescendant(true);
    } else {
      setIsDescendant(!isDescendant);
    }
    setOrderedBy(nextOrderedBy);
  };

  return (
    <table css={Styles.root}>
      <thead>
        <tr css={Styles.headerRow}>
          <TableHeaderCell
            onClickSort={() => onClickSort('name')}
            text="Name"
          />
          <TableHeaderCell
            onClickSort={() => onClickSort('id')}
            text="Integration ID"
          />
          <TableHeaderCell
            onClickSort={() => onClickSort('createdAt')}
            text="Submitted"
          />
          <TableHeaderCell
            onClickSort={() => onClickSort('updatedAt')}
            text="Last Updated"
          />
        </tr>
      </thead>
      <tbody>
        {reviewsSorted.map((review, idx) => (
          <TableRow key={review.id} isDark={idx % 2 === 1} review={review} />
        ))}
      </tbody>
    </table>
  );
}
